/* eslint-disable camelcase */
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { Container } from "@material-ui/core";
import { Grid, TextField, Box, Stack, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import { Form, FormikProvider, useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { useStores } from "../hooks/Services/useStores";
// ----------------------------------------------------------------------

export default function CustomerCreateEditPage() {
  const { state } = useLocation();
  const isEdit = state;
  const navigate = useNavigate();
  const { createStore, updateStore, isLoading } = useStores();

  const splitAddress = state?.completeAddress?.split(",");

  const storeSchema = Yup.object().shape({
    consultantPerMail: Yup.string()
      .email("E-mail inválido")
      .required("E-mail é necessário"),
    cnpj: Yup.string().required("CNPJ é necessário"),
    businessName: Yup.string().required("Razão social é necessária"),
    additionalPhone: Yup.string().required("Celular é necessário"),
    street: Yup.string().required("Rua é necessária"),
    district: Yup.string().required("Bairro é necessário"),
    number: Yup.string().required("Número é necessário"),
    city: Yup.string().required("Cidade é necessária"),
    state: Yup.string().required("Estado é necessário"),
  });

  const formik = useFormik({
    initialValues: {
      businessName: isEdit ? state?.businessName : "",
      consultantPerMail: isEdit ? state?.consultantPerMail : "",
      cnpj: isEdit ? state?.cnpj : "",
      additionalPhone: isEdit ? state?.additionalPhone : "",
      responsiblePerPhone: isEdit ? state?.responsiblePerPhone : "",
      street: splitAddress?.[0] || "",
      district: splitAddress?.[1] || "",
      number: splitAddress?.[2] || "",
      city: splitAddress?.[3] || "",
      state: splitAddress?.[4] || "",
      complement: splitAddress?.[5] || "",
    },
    validationSchema: storeSchema,
    onSubmit: async (values, { resetForm }) => {
      const objToSend = {
        businessName: values?.businessName,
        name: values?.businessName,
        responsiblePerName: values?.businessName,
        responsibleNameInspection: values?.businessName,

        cnpj: values?.cnpj?.replace(/\D/g, ""),
        completeAddress: `${values?.street}, ${values?.district}, ${values?.number}, ${values?.city}, ${values?.state}, ${values?.complement}`,
        responsiblePerMail: values?.consultantPerMail,
        consultantPerMail: values?.consultantPerMail,

        additionalPhone: String(values?.additionalPhone)?.replace(/\D/g, ""),
        responsiblePerPhone: String(values?.responsiblePerPhone)?.replace(
          /\D/g,
          ""
        ),
      };

      if (isEdit) {
        const objToUpdate = {
          ...objToSend,
          storeId: state?.id,
        };

        const updateStoreResponse = await updateStore(objToUpdate);

        if (updateStoreResponse?.success) {
          navigate("/customers/list");
        }

        return;
      }

      const createResponse = await createStore(objToSend);

      if (createResponse?.success) {
        resetForm();
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    submitForm,
    setFieldValue,
    values,
  } = formik;

  return (
    <>
      <Helmet>
        <title>Clientes</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Cliente - {isEdit ? "Editar" : "Criar"}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form
            onSubmit={handleSubmit}
            style={{ width: "100%", height: "100%" }}
          >
            <Box sx={{ width: "100%", height: "90%", marginBottom: "1rem" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Razão Social"
                    {...getFieldProps("businessName")}
                    error={Boolean(touched.businessName && errors.businessName)}
                    helperText={touched.businessName && errors.businessName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    {...getFieldProps("consultantPerMail")}
                    error={Boolean(
                      touched.consultantPerMail && errors.consultantPerMail
                    )}
                    helperText={
                      touched.consultantPerMail && errors.consultantPerMail
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    value={values?.cnpj}
                    onChange={(event) => {
                      const fieldValue = event.target.value;
                      setFieldValue("cnpj", fieldValue);
                    }}
                    onBlur={getFieldProps("cnpj").onBlur}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="CNPJ"
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={4}>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    value={values?.additionalPhone}
                    onChange={(event) => {
                      const fieldValue = event.target.value;
                      setFieldValue(
                        "additionalPhone",
                        fieldValue?.replace(/\D/g, "")
                      );
                    }}
                    onBlur={getFieldProps("additionalPhone").onBlur}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="Celular"
                        error={Boolean(
                          touched.additionalPhone && errors.additionalPhone
                        )}
                        helperText={
                          touched.additionalPhone && errors.additionalPhone
                        }
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={4}>
                  <InputMask
                    mask="(99) 9999-9999"
                    maskChar=""
                    value={values?.responsiblePerPhone}
                    onChange={(event) => {
                      const fieldValue = event.target.value;
                      setFieldValue(
                        "responsiblePerPhone",
                        fieldValue?.replace(/\D/g, "")
                      );
                    }}
                    onBlur={getFieldProps("responsiblePerPhone").onBlur}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="Telefone"
                        error={Boolean(
                          touched.responsiblePerPhone &&
                            errors.responsiblePerPhone
                        )}
                        helperText={
                          touched.responsiblePerPhone &&
                          errors.responsiblePerPhone
                        }
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Rua"
                    {...getFieldProps("street")}
                    error={Boolean(touched.street && errors.street)}
                    helperText={touched.street && errors.street}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    {...getFieldProps("district")}
                    error={Boolean(touched.district && errors.district)}
                    helperText={touched.district && errors.district}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Número"
                    {...getFieldProps("number")}
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    {...getFieldProps("city")}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Estado"
                    inputProps={{ maxLength: 2 }}
                    {...getFieldProps("state")}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    {...getFieldProps("complement")}
                    error={Boolean(touched.complement && errors.complement)}
                    helperText={touched.complement && errors.complement}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    height: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    sx={{ width: "30%", fontSize: "1rem" }}
                    variant="contained"
                    onClick={submitForm}
                    loading={isLoading}
                  >
                    {isEdit ? "Editar" : "Criar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
