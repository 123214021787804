import { useCallback, useContext, useState } from "react";
import api from "../api";
import { SnackBarContext } from "../../contexts/SnackBarContext";

export function useUsers() {
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getSurveyor = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await api.get(`/btck/list/surveyor/0/200`);

      if (data) {
        setResult(data);
      }
    } catch {
      setResult({});
      setStatus(500);
      setMessage("Sem conexão com o servidor!");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // role USERS
  const listUsers = async (page = 0, rowsPerPage = 10, searchingFor = "") => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/btck/list/users/${page}/${rowsPerPage}?searchingFor=${searchingFor}`
      );

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setResult({
        data: response?.data?.users,
        total: response?.data?.total,
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const createUser = async (datas) => {
    setIsLoading(true);
    try {
      const response = await api.post("/btck/create/user", datas);

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setSnackBarMessage({
        message: response?.data?.message,
        severity: "success",
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (datas) => {
    setIsLoading(true);
    try {
      const response = await api.post("/btck/update/user", datas);

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setSnackBarMessage({
        message: response?.data?.message,
        severity: "success",
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getSurveyor,
    result,
    isLoading,
    message,
    status,
    setMessage,
    listUsers,
    createUser,
    updateUser,
  };
}
