import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { SnackBarContext } from "../../contexts/SnackBarContext";

export const useSurveys = () => {
  const [result, setResult] = useState({ data: [] });
  const [params, setParams] = useState({ page: 0, rowsPerPage: 20 });
  const [message, setMessage] = useState({ message: "", show: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const searchSurvey = useCallback(
    async (dataSearch) => {
      setIsLoading(true);

      try {
        const queryParams = {
          params: {
            all: dataSearch[0].all,
          },
        };
        const { data } = await api.get(
          `/btck/list/surveys/${params.page}/${params.rowsPerPage}`,
          queryParams
        );

        if (data) {
          setResult(data);
        } else {
          setResult({ data: [], status: 400, message: "Erro." });
          navigate("/login");
        }
      } catch (error) {
        setResult({
          data: [],
          status: 500,
          message: "Sem conexão com o servidor!",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const searchSurveyByUuid = useCallback(
    async (surveyUuid) => {
      setIsLoading(true);

      try {
        const { data } = await api.get(`/btck/find/survey/${surveyUuid}`);

        if (data) {
          setResult(data);
        } else {
          setResult({ data: [], status: 400, message: "Erro." });
          navigate("/login");
        }
      } catch (error) {
        setResult({
          data: [],
          status: 500,
          message: "Sem conexão com o servidor!",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const replySurveyLikeDealer = useCallback(async (dataReply) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("aboutVeh", JSON.stringify(dataReply.formValues));
      formData.append("survey", JSON.stringify(dataReply.survey));

      Object.keys(dataReply.fotos).forEach((groupKey) => {
        const group = dataReply.fotos[groupKey];

        if (groupKey === "fileDocs") {
          const getArray = group.docVehicle || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "btckDealerDocVehicle");
          });
        }

        if (groupKey === "vehiclePics") {
          const getArray = group.vehiclePics || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "btckDealerVehiclePics");
          });
        }
      });

      const { data } = await api.data("/btck/survey/replydealer", formData);

      if (data) {
        setMessage({ message: data.message, status: 200, show: true });
        setResult(data);
        setTimeout(() => {
          navigate("/dashboard/ticket");
        }, 500);
      } else {
        setResult({ data: [] });
        setMessage("Erro data");
      }
    } catch {
      setResult({
        data: {},
        status: 500,
        message: "Sem conexão com o servidor!",
      });
      setMessage("Erro servidor");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const replySurvey = useCallback(async (dataReply) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("reply", JSON.stringify(dataReply.formValues));
      formData.append("survey", JSON.stringify(dataReply.survey));
      const group = dataReply.fileCilia;
      formData.append("arquivo", group);

      const { data } = await api.data("/btck/survey/reply", formData);

      if (data) {
        setResult(data);
        setMessage({ message: "Sucesso", status: 200 });
        navigate("/dashboard/ticket");
        // navigate('/dashboard/ticket')
      } else {
        setResult({ data: [] });
        setMessage({ message: "Erro ao responder vistoria", status: 500 });
      }
    } catch {
      setResult({ data: {} });
      setMessage({ message: "Sem conexão com o servidor", status: 500 });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const returnSurvey = useCallback(async (returnData) => {
    try {
      const { data } = await api.post("/btck/survey/return", { returnData });

      if (data.message) {
        setMessage({ message: data.message, show: 200 });
        navigate("/dashboard/ticket");
      } else {
        setMessage({ message: "Erro ao responder vistoria", status: 500 });
      }
    } catch {
      setResult({ data: {} });
      setMessage({ message: "Sem conexão com o servidor", status: 500 });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toAssignSurvey = useCallback(async (dataReply) => {
    setIsLoading(true);

    try {
      const { data } = await api.post("/btck/survey/assign", dataReply);

      if (data) {
        const user = dataReply.assignedUser.label;
        // setMessage({ message: `Atribuido com sucesso: ${user}`, status: 200 });
        setSnackBarMessage({
          message: `Atribuido com sucesso: ${user}`,
          severity: "success",
        });
        setResult(data);
        navigate("/dashboard/ticket");
      } else {
        setResult({ data: [] });
        setMessage({ message: "Erro ao responder vistoria", status: 500 });
      }
    } catch {
      setResult({ data: {} });
      setMessage({ message: "Sem conexão com o servidor", status: 500 });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateSurvey = useCallback(async (dataToUpdate) => {
    setIsLoadingUpdate(true);

    try {
      const { data } = await api.post("/btck/survey/update", { dataToUpdate });

      if (data.success) {
        setSnackBarMessage({
          message: `${data.message}`,
          severity: "success",
        });
        return { dataToUpdate, success: true };
      } else {
        setSnackBarMessage({
          message: `${data.message}`,
          severity: "error",
        });
        return { success: false };
      }
    } catch {
      setSnackBarMessage({
        message: "Sem conexão com o servidor",
        severity: "error",
      });
      setResult({ data: {} });
      setMessage({ message: "Sem conexão com o servidor", status: 500 });
    } finally {
      setIsLoadingUpdate(false);
    }
  }, []);

  return {
    result,
    isLoading,
    message,
    setMessage,
    params,
    setParams,
    searchSurvey,
    replySurvey,
    returnSurvey,
    toAssignSurvey,
    isLoadingUpdate,
    updateSurvey,
    replySurveyLikeDealer,
    searchSurveyByUuid,
  };
};
