import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import useResponsive from "../../hooks/useResponsive";
import { useContext } from "react";
import { SnackBarContext } from "../../contexts/SnackBarContext";

const CardKanban = ({ infoCards }) => {
  const { status, uuid } = infoCards;
  const { setSnackBarMessage } = useContext(SnackBarContext);

  const navigate = useNavigate();

  const isMobile = useResponsive("down", "sm");
  const isTablet = useResponsive("between", "sm", "md");

  // primary - 2065d1
  // warning - ffc108
  // success - 5fd03a
  // info - 1e93ff
  // error - ff4842

  return (
    <Box
      sx={{
        width: isMobile ? "50vw" : isTablet ? "30vw" : "100%",
        color: "black",
        borderRadius: "10px",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor:
          status === 0
            ? "#2064d136"
            : status === 1
            ? "#ffc1083d"
            : status === 4
            ? "#60d03a45"
            : status === 5
            ? "#1e92ff47"
            : "#ff484253",
        border:
          status === 0
            ? "2px solid #2065d1"
            : status === 1
            ? "2px solid #ffc108"
            : status === 4
            ? "2px solid #5fd03a"
            : status === 5
            ? "2px solid #1e93ff"
            : "2px solid #ff4842",
      }}
    >
      {infoCards?.returnSurveyMessage?.length > 0 && status === 1 && (
        <Box
          sx={{
            position: "absolute",
            right: 10,
          }}
        >
          <Icon
            icon="streamline:return-2-solid"
            style={{ fontSize: "15px", color: "black" }}
          />
        </Box>
      )}

      <span>Marca: {infoCards?.brand || "-"}</span>
      <span>Modelo: {infoCards?.model || "-"}</span>
      <span>Placa: {infoCards?.plate || "-"}</span>
      <span>Ano: {infoCards?.year || "-"}</span>
      <span>Cod: {infoCards?.cod || "-"}</span>

      <Box
        display="flex"
        gap="0.5rem"
        mt={1}
        flexDirection={isMobile || isTablet ? "column" : "none"}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/dashboard/view-ticket", { state: infoCards });
          }}
          sx={{
            color: "black",
            borderColor: "black",
          }}
        >
          Abrir
        </Button>
        {status === 4 && (
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/dashboard/reply-ticket", { state: infoCards });
            }}
            sx={{
              color: "black",
              borderColor: "black",
            }}
          >
            {" "}
            Responder
          </Button>
        )}
      </Box>

      {uuid && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            cursor: "pointer",
            marginTop: "1rem",
          }}
        >
          <Box
            width="fit-content"
            sx={{
              transition: "0.2s",
              "&:hover": {
                transform: "scale(1.05)",
              },

              "&:active": {
                transform: "scale(0.95)",
              },
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(
                  `${process.env.REACT_APP_MAIN_PATH}/dashboard/public-view-ticket/${uuid}`
                )
                .then(() => {
                  setSnackBarMessage({
                    message:
                      "Link da vistoria copiado para a área de transferência!",
                    severity: "success",
                  });
                })
                .catch(() => {
                  setSnackBarMessage({
                    message:
                      "Erro ao copiar link da vistoria para a área de transferência!",
                    severity: "error",
                  });
                });
            }}
          >
            <Icon icon="icon-park-solid:share-one" width={25} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardKanban;
