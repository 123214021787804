import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@material-ui/core";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginRight: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

PagesToolbar.propTypes = {
  searchingFor: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  reload: PropTypes.func,
  hideSearchField: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
};

export default function PagesToolbar({
  searchingFor,
  onChange,
  onSearch,
  reload,
  hideSearchField,
  hideSearchIcon,
}) {
  return (
    <RootStyle>
      <Grid container className="justify-content-between" spacing={2}>
        {!hideSearchField && (
          <Grid item md={3} xs={5}>
            <SearchStyle
              style={{ padding: "0px" }}
              value={searchingFor}
              onChange={(e) => onChange(e.target.value, "quoteFilter")}
              placeholder="Procurar..."
              startAdornment={
                <InputAdornment position="start" style={{ paddingLeft: 0 }}>
                  <IconButton onClick={onSearch} id="Search">
                    <Icon icon="ic:baseline-search" width={20} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        )}

        {!hideSearchIcon && (
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={reload}>
              <Icon icon="tabler:reload" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </RootStyle>
  );
}
