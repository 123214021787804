import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  CircularProgress,
  Box,
} from "@mui/material";
// components
import { useNavigate } from "react-router-dom";
import { getComparator, applySortFilter } from "src/utils/sortFilterComparator";
import { useSurveys } from "../hooks/Services/useSurveys";
import AlertSnackbar from "../components/alert/AlertSnackbar";

import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import SurveySkeleton from "../components/skeleton/SurveySkeleton";
import Kanban from "../components/kanban";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "plate", label: "Placa", alignRight: false },
  { id: "brand", label: "Marca", alignRight: false },
  { id: "model", label: "Modelo", alignRight: false },
  { id: "color", label: "Cor", alignRight: false },
  { id: "year", label: "Ano", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SurveysPage() {
  const {
    result,
    params,
    searchSurvey,
    setParams,
    acceptSurvey,
    refuseSurvey,
    isLoading,
  } = useSurveys();

  const [surveys, setSurveys] = useState([]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("id");

  const [filterName, setFilterName] = useState("");

  const [filterPlacaValue, setFilterPlacaValue] = useState("");

  const [filterStatusValue, setFilterStatusValue] = useState({
    label: "",
    value: "",
  });

  const [filterValue, setFilterValue] = useState("plate");

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rselected, setRSelected] = useState("");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const [filterAllValue, setFilterAllValue] = useState("");

  const searchFields = [
    {
      title: "Pesquisar",
      apiField: "all",
      type: "text",
      onChange: (e) => setFilterAllValue(e.target.value),
      inputValue: filterAllValue,
    },
  ];

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";

    if (isDesc && orderBy === property) {
      setOrderBy("id");
      setOrder("desc");
    } else if (orderBy === property) {
      setOrder("desc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = surveys.map((n) => n.plate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams({ ...params, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
    setParams({ ...params, page: 0 });
  };

  const filteredSurveys = applySortFilter(
    surveys,
    getComparator(order, orderBy)
  );

  const isNotFound = !filteredSurveys.length && !isLoading;

  useEffect(() => {
    setSurveys(result.data);
  }, [result]);

  const handleFilterChange = (selectedFilter) => {
    setFilterName("");
    setFilterValue(selectedFilter);
  };

  const resetFilters = () => {
    setPage(0);
    setFilterPlacaValue("");
    setFilterStatusValue({ label: "", value: "" });
    setParams({ ...params, page: 0 });
  };

  return (
    <>
      <Helmet>
        <title>Vistorias</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Vistorias
          </Typography>
          {/* <Button onClick={() => { navigate('/dashboard/new-ticket') }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Nova vistoria
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchSurvey}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Kanban datas={filteredSurveys} />

          <TablePagination
            count={result.total ?? 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "15px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "15px",
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/view-ticket", { state: rselected });
          }}
        >
          <Iconify icon={"majesticons:open"} sx={{ mr: 2 }} />
          Abrir
        </MenuItem>

        {rselected.status === 4 && (
          <MenuItem
            onClick={() => {
              navigate("/dashboard/reply-ticket", { state: rselected });
            }}
          >
            <Iconify icon={"gridicons:reply"} sx={{ mr: 2 }} />
            Responder
          </MenuItem>
        )}
      </Popover>

      <AlertSnackbar
        openAlert={error}
        duration={2500}
        message={message}
        status={500}
        onClose={() => setError(false)}
      />

      <Modal
        open={isLoading}
        className="d-flex justify-content-center align-items-center"
      >
        <CircularProgress color="inherit" />
      </Modal>
    </>
  );
}
