import { useContext, useState } from "react";
import api from "../api";
import { SnackBarContext } from "../../contexts/SnackBarContext";

export function useStores() {
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const listStores = async (page = 0, rowsPerPage = 10, searchingFor = "") => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/btck/list/stores/${page}/${rowsPerPage}?searchingFor=${searchingFor}`
      );

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setResult({
        data: response?.data?.stores,
        total: response?.data?.total,
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const createStore = async (datas) => {
    setIsLoading(true);
    try {
      const response = await api.post("/btck/create/store", datas);

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setSnackBarMessage({
        message: response?.data?.message,
        severity: "success",
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const updateStore = async (datas) => {
    setIsLoading(true);
    try {
      const response = await api.post("/btck/update/store", datas);

      if (!response?.data?.success) throw new Error(response?.data?.message);

      setSnackBarMessage({
        message: response?.data?.message,
        severity: "success",
      });

      return {
        success: true,
      };
    } catch (error) {
      setSnackBarMessage({
        message: error?.message,
        severity: "error",
      });
      return {
        success: false,
        message: error?.message,
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    result,
    isLoading,
    message,
    setMessage,
    listStores,
    updateStore,
    createStore,
  };
}
