import React from "react";
import PropTypes from "prop-types";
import { Typography, Paper } from "@mui/material";

NoListItems.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default function NoListItems({ pageTitle, ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Não há {pageTitle}
      </Typography>
    </Paper>
  );
}
