import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
} from "@mui/material";
// components
import { useParams } from "react-router-dom";
import { useSurveys } from "../hooks/Services/useSurveys";
import { Document, Page, pdfjs } from "react-pdf";

import { Carousel } from "react-responsive-carousel";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ReactViewer from "react-viewer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Scrollbar from "../components/scrollbar";
import { Icon } from "@iconify/react";
import { fCurrency } from "src/utils/formatNumber";
import AlertSnackbar from "src/components/alert/AlertSnackbar";
import RenderPdfs from "../components/renderPdfs";
import CarCroqui from "../components/carCroqui";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PublicViewSurvey() {
  const { uuid } = useParams();
  const [props, setProps] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.65);
  const [showAlert, setShowAlert] = useState(false);
  const { result: surveyResult, message, searchSurveyByUuid } = useSurveys();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    searchSurveyByUuid(uuid);
  }, []);

  useEffect(() => {
    if (surveyResult) {
      setProps(surveyResult?.data);
    }
  }, [surveyResult]);

  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openViewer = (src) => {
    setCurrentImage(src);
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  const vehiclePhotosRefArray = [
    "vehiclePics",
    "dealerVehiclePics",
    "btckDealerVehiclePics",
  ];

  const vehicleDocsPhotosRefArray = [
    "docVehicle",
    "dealerDocVehicle",
    "btckDealerDocVehicle",
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const customPageStyles = {
    textLayer: {
      display: "none", // Oculta a camada de texto para evitar a sobreposição
    },
  };

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Lógica para calcular a escala apropriada com base no tamanho da janela
      const newScale = Math.min(windowWidth / 800, windowHeight / 600);
      setScale(newScale);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (message.message !== "") {
      setShowAlert(true);
    }
  }, [message]);

  return !props ? (
    <LoadingScreen />
  ) : (
    <>
      <Helmet>
        <title>Detalhes</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Detalhes
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.brand || ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.model || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.year || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.color || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.plate || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.chassis || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.additional || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      variant="outlined"
                      className="w-100"
                      disabled
                      value={props?.cod || ""}
                    />
                  </Grid>
                  {props?.notes?.customerNotes && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações do Cliente"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={props?.notes?.customerNotes || ""}
                        disabled
                      />
                    </Grid>
                  )}

                  {props?.notes?.bcNotesToCustomer && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações para o cliente"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={props?.notes?.bcNotesToCustomer || ""}
                        disabled
                      />
                    </Grid>
                  )}

                  {props?.notes?.bcNotesToDealer && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações para o vistoriador"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={props?.notes?.bcNotesToDealer || ""}
                        disabled
                      />
                    </Grid>
                  )}

                  {props?.notes?.dealerNotes && (
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações do vistoriador"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        value={props?.notes?.dealerNotes || ""}
                        disabled
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12} className="text-center">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={props?.armored ?? false} />}
                      label="Veículo Blindado"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            {props?.positionsChecked && (
              <CarCroqui positionsChecked={props?.positionsChecked} />
            )}

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Foto do veículo
              </Typography>
              <Carousel>
                {props.photos ? (
                  props.photos
                    .filter((photo) => vehiclePhotosRefArray.includes(photo.ref))
                    .map((photo, index) => (
                      <div key={index}>
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            padding: 0,
                          }}
                          onClick={() => openViewer(index)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              openViewer(index);
                            }
                          }}
                          aria-label={`Ver imagem ${index + 1}`}
                        >
                          <SideBySideMagnifier
                            imageSrc={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                            imageAlt={`Foto do veículo ${index + 1}`}
                            style={{ cursor: "pointer" }}
                          />
                        </button>
                      </div>
                    ))
                ) : (
                  <Typography variant="body2">Nenhuma foto anexada</Typography>
                )}
              </Carousel>

              <ReactViewer
                visible={visible}
                onClose={closeViewer}
                images={(props.photos && props.photos.length > 0)
                  ? props.photos
                      .filter((photo) => vehiclePhotosRefArray.includes(photo.ref))
                      .map((photo) => ({
                        src: `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                        alt: "Imagem do veículo",
                      }))
                  : []
                }
                index={currentImageIndex}
                zIndex={1000}
              />
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Documentação
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {props?.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehicleDocsPhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => {
                        const pdf = photo.foto.endsWith(".pdf");

                        return pdf ? (
                          <Grid
                            item
                            xs={1.5}
                            md={1.5}
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              style={{ cursor: "pointer" }}
                              width={100}
                              icon="vscode-icons:file-type-pdf2"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                                  "_blank"
                                )
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={3} md={3} key={index}>
                            <img
                              width={200}
                              src={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              alt={photo.ref}
                              title={photo.ref}
                            />
                          </Grid>
                        );
                      })
                  ) : (
                    <Grid item xs={12}>
                      <Paper sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          Sem fotos anexadas
                        </Typography>
                        <Typography variant="body2">
                          Nenhuma foto dos documentos anexada
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>

            {props?.Replies?.length !== 0 && (
              <>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Mão de Obra"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(props?.Replies?.[0]?.labor || 0)}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Valor das peças"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(props?.Replies?.[0]?.pieces || 0)}
                    />
                  </Grid>

                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Total"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(
                        Number(props?.Replies?.[0]?.pieces) +
                          Number(props?.Replies?.[0]?.labor) || 0
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Descrição"
                      disabled
                      variant="outlined"
                      rows={3}
                      multiline
                      className="w-100"
                      value={props?.Replies?.[0]?.additional || ""}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <RenderPdfs pdfArray={props?.Replies} />
                  </Grid>

                  <Card
                    sx={{ boxShadow: 3, width: "100%" }}
                    className="mb-4 d-flex justify-content-center"
                  ></Card>
                  {props?.Replies?.length > 0 &&
                    props?.Replies?.[0]?.pdf?.endsWith(".pdf") && (
                      <Box
                        className="d-flex mb-1 mt-3"
                        style={{ maxWidth: "100vw", overflowX: "auto" }}
                      >
                        <Document
                          file={
                            props?.Replies?.[0]
                              ? `${process.env.REACT_APP_API_ENDPOINT}/replyes/${props?.Replies?.[0]?.pdf}`
                              : null
                          }
                          onLoadSuccess={onDocumentLoadSuccess}
                          renderMode="canvas"
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              renderTextLayer={false} // Desativa a renderização da camada de texto padrão
                              customStyles={customPageStyles} // Aplica estilos personalizados para cada página
                              renderAnnotationLayer={false}
                              scale={scale}
                            />
                          ))}
                        </Document>
                      </Box>
                    )}
                </Grid>
              </>
            )}
          </Scrollbar>
        </Card>

        <AlertSnackbar
          openAlert={showAlert}
          duration={2500}
          status={message.status}
          message={message.message}
          onClose={handleAlert}
        />
      </Container>
    </>
  );
}
