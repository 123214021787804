/* eslint-disable camelcase */
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { Container } from "@material-ui/core";
import {
  Grid,
  TextField,
  Box,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { useUsers } from "../hooks/Services/useUsers";
import { useStores } from "../hooks/Services/useStores";
import { useContext, useEffect } from "react";
import { SnackBarContext } from "../contexts/SnackBarContext";
// ----------------------------------------------------------------------

export default function UserCreateEditPage() {
  const { state } = useLocation();
  const isEdit = state;
  const { setSnackBarMessage } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { createUser, isLoading, updateUser, message } = useUsers();
  const {
    listStores,
    isLoading: loadingStores,
    result: resultStore,
  } = useStores();

  const handleListStores = async () => {
    await listStores(0, 100, "");
  };

  useEffect(() => {
    handleListStores();
  }, []);

  const userSchema = Yup.object().shape({
    name: Yup.string().required("Nome é necessário"),
    dealer: Yup.string().required("Oficina é necessária"),
    email: Yup.string()
      .email("E-mail inválido")
      .required("E-mail é necessário"),
    ...(!isEdit && { password: Yup.string().required("Senha é necessária") }),
  });

  const formik = useFormik({
    initialValues: {
      name: isEdit ? state?.name : "",
      email: isEdit ? state?.email : "",
      dealer: isEdit ? state?.storeId : "",
      password: isEdit ? state?.password : "",
    },
    validationSchema: userSchema,
    onSubmit: async (values, { resetForm }) => {
      const objToSend = {
        name: values?.name,
        email: values?.email,
        dealer: values?.dealer,
        ...(!isEdit && { password: values?.password }),
      };

      if (isEdit) {
        const objToUpdate = {
          ...objToSend,
          userId: state?.id,
        };

        const updateUserResponse = await updateUser(objToUpdate);

        if (updateUserResponse?.success) {
          navigate("/users/list");
        }

        return;
      }

      const createResponse = await createUser(objToSend);

      if (createResponse?.success) {
        resetForm();
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    submitForm,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (Object.keys(errors)?.includes("dealer")) {
      setSnackBarMessage({
        message: errors?.dealer,
        severity: "error",
      });
    }
  }, [errors]);

  return (
    <>
      <Helmet>
        <title>Usuários</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Usuário - {isEdit ? "Editar" : "Criar"}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form
            onSubmit={handleSubmit}
            style={{ width: "100%", height: "100%" }}
          >
            <Box sx={{ width: "100%", height: "90%", marginBottom: "1rem" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Oficina
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.dealer}
                      label="Oficina"
                      onChange={(e) => setFieldValue("dealer", e.target.value)}
                      {...getFieldProps("dealer")}
                      error={Boolean(touched.dealer && errors.dealer)}
                      helperText={touched.dealer && errors.dealer}
                    >
                      {resultStore &&
                        resultStore?.data?.map((store) => (
                          <MenuItem value={store?.id}>{store?.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={!isEdit ? 6 : 12}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                {!isEdit && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Senha"
                      {...getFieldProps("password")}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    height: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    sx={{ width: "30%", fontSize: "1rem" }}
                    variant="contained"
                    onClick={submitForm}
                    loading={isLoading || loadingStores}
                  >
                    {isEdit ? "Editar" : "Criar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
