import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { MuiFileInput } from "mui-file-input";
import * as Yup from "yup";
// @mui
import {
  Card,
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  Paper,
  Modal,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useLocation } from "react-router-dom";
import { useUsers } from "../hooks/Services/useUsers";
import { useSurveys } from "../hooks/Services/useSurveys";
import { Document, Page, pdfjs } from "react-pdf";

import { Carousel } from "react-responsive-carousel";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ReactViewer from "react-viewer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Scrollbar from "../components/scrollbar";
import ErrorMessageForm from "../components/errorMessageForm";
import { Icon } from "@iconify/react";
import { fCurrency } from "src/utils/formatNumber";
import AlertSnackbar from "src/components/alert/AlertSnackbar";
import { SnackBarContext } from "src/contexts/SnackBarContext";
import RenderPdfs from "../components/renderPdfs";
import CarCroqui from "../components/carCroqui";

// ----------------------------------------------------------------------
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewSurveys() {
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const [assignUser, setAssignUser] = useState(false);
  const { result, isLoading, getSurveyor } = useUsers();
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.65);
  const [showAlert, setShowAlert] = useState(false);
  const {
    isAssigning,
    message,
    toAssignSurvey,
    isLoadingUpdate,
    updateSurvey,
    replySurveyLikeDealer,
  } = useSurveys();

  console.log("props", props);

  const { setSnackBarMessage } = useContext(SnackBarContext);

  const [assignedUser, setAssignedUser] = useState(null);
  const [disableField, setDisableField] = useState(true);

  const [openAnswerSurvey, setOpenAnswerSurvey] = useState(false);

  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [docsViewerVisible, setDocsViewerVisible] = useState(false);
  const [docsCurrentImageIndex, setDocsCurrentImageIndex] = useState(0);

  const openDocsViewer = (index) => {
    setDocsCurrentImageIndex(index);
    setDocsViewerVisible(true);
  };

  const closeDocsViewer = () => {
    setDocsViewerVisible(false);
  };

  const openViewer = (src) => {
    setCurrentImage(src);
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  useEffect(() => {
    getSurveyor();
  }, []);

  useEffect(() => {
    if (disableField) {
      resetForm();
    }
  }, [disableField]);

  useEffect(() => {
    if (props) {
      setFieldValue("brand", props.brand || "");
      setFieldValue("model", props.model || "");
      setFieldValue("year", props.year || "");
      setFieldValue("color", props.color || "");
      setFieldValue("plate", props.plate || "");
      setFieldValue("chassis", props.chassis || "");
      setFieldValue("additional", props.additional || "");
      setFieldValue("cod", props.cod || "");
      setFieldValue("customerNotes", props?.notes?.customerNotes || "");
      setFieldValue("bcNotesToDealer", props?.notes?.bcNotesToDealer || "");
      setFieldValue("bcNotesToCustomer", props?.notes?.bcNotesToCustomer || "");
      setFieldValue("dealerNotes", props?.notes?.dealerNotes || "");
    }
  }, [props]);

  const SchemaSurvey = Yup.object().shape({
    ...(!disableField && {
      brand: Yup.string().required("Marca é necessária"),
      model: Yup.string().required("Modelo é necessário"),
      year: Yup.string().required("Ano é necessário"),
      color: Yup.string().required("Cor é necessária"),
      plate: Yup.string().required("Placa é necessária"),
      chassis: Yup.string().required("Chassi é necessário"),
    }),
    ...(openAnswerSurvey && {
      fotos: Yup.object().shape({
        vehiclePics: Yup.object().shape({
          vehiclePics: Yup.mixed().required("Fotos do veículo são necessárias"),
        }),
        fileDocs: Yup.object().shape({
          docVehicle: Yup.mixed().required(
            "Fotos dos documentos do veículo são necessárias"
          ),
        }),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      id: props?.id,
      brand: props?.brand || "",
      model: props?.model || "",
      year: props?.year || "",
      color: props?.color || "",
      plate: props?.plate || "",
      chassis: props?.chassis || "",
      additional: props?.additional || "",
      cod: props?.cod || "",
      customerNotes: props?.notes?.customerNotes || "",
      bcNotesToCustomer: props?.notes?.bcNotesToCustomer || "",
      bcNotesToDealer: props?.notes?.bcNotesToDealer || "",
      dealerNotes: props?.notes?.dealerNotes || "",
      fotos: {
        vehiclePics: {
          vehiclePics: [],
        },
        fileDocs: {
          docVehicle: [],
        },
      },
    },
    validationSchema: SchemaSurvey,
    onSubmit: async (values) => {
      if (!disableField) {
        const response = await updateSurvey(values);

        if (response.success) {
          setProps((prevState) => {
            const newObj = {
              ...prevState,
              additional: response.dataToUpdate.additional || "",
              brand: response.dataToUpdate.brand || "",
              chassis: response.dataToUpdate.chassis || "",
              cod: response.dataToUpdate.cod || "",
              color: response.dataToUpdate.color || "",
              model: response.dataToUpdate.model || "",
              plate: response.dataToUpdate.plate || "",
              year: response.dataToUpdate.year || "",
            };

            return newObj;
          });
        }

        setDisableField(true);
      }

      if (openAnswerSurvey) {
        if (
          values.fotos.fileDocs.docVehicle.length === 0 ||
          values.fotos.vehiclePics.vehiclePics.length === 0
        ) {
          setSnackBarMessage({
            message:
              "É necessário adicionar pelo menos uma foto nos campos de foto",
            severity: "error",
          });
          return;
        }

        const {
          additional,
          brand,
          chassis,
          color,
          model,
          year,
          bcNotesToCustomer,
          bcNotesToDealer,
        } = values;

        const dataReply = {
          formValues: {
            additional,
            brand,
            chassis,
            color,
            model,
            year,
            bcNotesToCustomer,
            bcNotesToDealer,
            currentNotes: props?.notes,
          },
          fotos: { ...values.fotos },
          survey: values.id,
        };

        replySurveyLikeDealer(dataReply);
      }
    },
  });

  const {
    getFieldProps,
    submitForm,
    errors,
    touched,
    resetForm,
    setFieldValue,
    values,
    isSubmitting,
  } = formik;

  const handleInputChange = (name, event) => {
    setFieldValue("fotos", {
      ...values.fotos,
      fileDocs: {
        [name]: event,
      },
    });
  };

  const handleInputChangeVehiclePic = (name, event) => {
    setFieldValue("fotos", {
      ...values.fotos,
      vehiclePics: {
        [name]: event,
      },
    });
  };

  const vehiclePhotosRefArray = [
    "vehiclePics",
    "dealerVehiclePics",
    "btckDealerVehiclePics",
  ];

  const usersWithOptions = result?.data.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const vehicleDocsPhotosRefArray = [
    "docVehicle",
    "dealerDocVehicle",
    "btckDealerDocVehicle",
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const customPageStyles = {
    textLayer: {
      display: "none", // Oculta a camada de texto para evitar a sobreposição
    },
  };

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Lógica para calcular a escala apropriada com base no tamanho da janela
      const newScale = Math.min(windowWidth / 800, windowHeight / 600);
      setScale(newScale);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (message.message !== "") {
      setShowAlert(true);
    }
  }, [message]);

  return (
    <>
      <Helmet>
        <title>Detalhes</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Detalhes
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Box className="d-flex mb-1 mt-3">
                <FormikProvider value={formik}>
                  <Form>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sx={{ marginBottom: "0.5rem" }}>
                        <Typography variant="h6">Endereço</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Rua"
                          disabled
                          value={props?.deliveryStreet}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Bairro"
                          disabled
                          value={props?.deliveryDistrict}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-basic"
                          label="Número"
                          disabled
                          value={props?.deliveryNumber}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-basic"
                          label="Cidade"
                          disabled
                          value={props?.deliveryCity}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-basic"
                          label="CEP"
                          disabled
                          value={props?.deliveryCep}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="outlined-basic"
                          label="Estado"
                          disabled
                          value={props?.deliveryState}
                          variant="outlined"
                          className="w-100"
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        mb={1}
                        justifyContent="space-between"
                        display="flex"
                        alignItems="center"
                        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                      >
                        <Typography variant="h6">Veículo</Typography>
                        {!disableField && (
                          <LoadingButton
                            onClick={submitForm}
                            style={{
                              width: "10%",
                            }}
                            variant="contained"
                            loading={isLoadingUpdate}
                          >
                            Salvar
                          </LoadingButton>
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Marca"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("brand")}
                          helperText={Boolean(touched.brand && errors.brand)}
                          error={Boolean(touched.brand && errors.brand)}
                        />
                        {touched.brand && errors.brand && (
                          <ErrorMessageForm>{errors.brand}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Modelo"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("model")}
                          helperText={Boolean(touched.model && errors.model)}
                          error={Boolean(touched.model && errors.model)}
                        />
                        {touched.model && errors.model && (
                          <ErrorMessageForm>{errors.model}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="Ano"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("year")}
                          helperText={Boolean(touched.year && errors.year)}
                          error={Boolean(touched.year && errors.year)}
                        />
                        {touched.year && errors.year && (
                          <ErrorMessageForm>{errors.year}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="Cor"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("color")}
                          helperText={Boolean(touched.color && errors.color)}
                          error={Boolean(touched.color && errors.color)}
                        />
                        {touched.color && errors.color && (
                          <ErrorMessageForm>{errors.color}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="Placa"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("plate")}
                          helperText={Boolean(touched.plate && errors.plate)}
                          error={Boolean(touched.plate && errors.plate)}
                        />
                        {touched.plate && errors.plate && (
                          <ErrorMessageForm>{errors.plate}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="Chassi"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("chassis")}
                          helperText={Boolean(
                            touched.chassis && errors.chassis
                          )}
                          error={Boolean(touched.chassis && errors.chassis)}
                        />
                        {touched.chassis && errors.chassis && (
                          <ErrorMessageForm>{errors.chassis}</ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Dados Adicionais"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("additional")}
                          helperText={Boolean(
                            touched.additional && errors.additional
                          )}
                          error={Boolean(
                            touched.additional && errors.additional
                          )}
                        />
                        {touched.additional && errors.additional && (
                          <ErrorMessageForm>
                            {errors.additional}
                          </ErrorMessageForm>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="outlined-basic"
                          label="Identificação"
                          disabled={disableField}
                          variant="outlined"
                          className="w-100"
                          {...getFieldProps("cod")}
                          helperText={Boolean(touched.cod && errors.cod)}
                          error={Boolean(touched.cod && errors.cod)}
                        />
                        {touched.cod && errors.cod && (
                          <ErrorMessageForm>{errors.cod}</ErrorMessageForm>
                        )}
                      </Grid>
                      {values?.customerNotes && (
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Observações do Cliente"
                            variant="outlined"
                            className="w-100"
                            name="notes"
                            values={values?.customerNotes || ""}
                            {...getFieldProps("customerNotes")}
                            disabled
                          />
                        </Grid>
                      )}

                      {values?.bcNotesToCustomer && (
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Observações para o cliente"
                            variant="outlined"
                            className="w-100"
                            name="notes"
                            values={values?.bcNotesToCustomer || ""}
                            {...getFieldProps("bcNotesToCustomer")}
                            disabled
                          />
                        </Grid>
                      )}

                      {values?.bcNotesToDealer && (
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Observações para o vistoriador"
                            variant="outlined"
                            className="w-100"
                            name="notes"
                            values={values?.bcNotesToDealer || ""}
                            {...getFieldProps("bcNotesToDealer")}
                            disabled
                          />
                        </Grid>
                      )}
                      {values?.dealerNotes && (
                        <Grid item lg={12} md={12} xs={12}>
                          <TextField
                            id="outlined-basic"
                            label="Observações do vistoriador"
                            variant="outlined"
                            className="w-100"
                            name="notes"
                            values={values?.dealerNotes || ""}
                            {...getFieldProps("dealerNotes")}
                            disabled
                          />
                        </Grid>
                      )}

                      <Grid item xs={6} md={6} className="text-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!disableField}
                              onChange={() => {
                                setDisableField((prevState) => !prevState);
                                setOpenAnswerSurvey(false);
                              }}
                            />
                          }
                          label="Editar dados do veículo"
                        />
                      </Grid>

                      <Grid item xs={6} md={6} className="text-center">
                        <FormControlLabel
                          disabled={disableField}
                          control={<Checkbox checked={props?.armored} />}
                          label="Veículo Blindado"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </FormikProvider>
              </Box>
            </Card>

            {props?.positionsChecked && (
              <CarCroqui positionsChecked={props?.positionsChecked} />
            )}

            <div style={{ display: "flex" }}>
              <Card sx={{ boxShadow: 3, width: "50%" }} className="mb-4">
                <Typography variant="h6" gutterBottom>
                  Foto do veículo
                </Typography>
                <Carousel>
                  {props.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehiclePhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => (
                        <div
                          key={index}
                          style={{ width: "500px", height: "500px" }}
                        >
                          <button
                            style={{
                              border: "none",
                              background: "none",
                              padding: 0,
                            }}
                            onClick={() => openViewer(index)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                openViewer(index);
                              }
                            }}
                            aria-label={`Ver imagem ${index + 1}`}
                          >
                            <SideBySideMagnifier
                              imageSrc={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              imageAlt={`Foto do veículo ${index + 1}`}
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                        </div>
                      ))
                  ) : (
                    <Typography variant="body2">
                      Nenhuma foto anexada
                    </Typography>
                  )}
                </Carousel>

                <ReactViewer
                  visible={visible}
                  onClose={closeViewer}
                  images={props.photos
                    .filter((photo) =>
                      vehiclePhotosRefArray.includes(photo.ref)
                    )
                    .map((photo) => ({
                      src: `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                      alt: "Imagem do veículo",
                    }))}
                  index={currentImageIndex}
                  zIndex={1000}
                />
              </Card>

              <Card sx={{ boxShadow: 3, width: "50%" }} className="mb-4">
                <Typography variant="h6" gutterBottom>
                  Documentação
                </Typography>
                <Carousel>
                  {props.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehicleDocsPhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => {
                        const pdf = photo.foto.endsWith(".pdf");

                        return pdf ? (
                          <div key={index}>
                            <button
                              style={{
                                border: "none",
                                background: "none",
                                padding: 0,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                                  "_blank"
                                )
                              }
                              aria-label={`Abrir PDF ${index + 1}`}
                            >
                              <Icon
                                width={100}
                                icon="vscode-icons:file-type-pdf2"
                                style={{ cursor: "pointer" }}
                              />
                            </button>
                          </div>
                        ) : (
                          <div
                            key={index}
                            style={{ width: "500px", height: "500px" }}
                          >
                            <button
                              style={{
                                border: "none",
                                background: "none",
                                padding: 0,
                              }}
                              onClick={() => openDocsViewer(index)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  openDocsViewer(index);
                                }
                              }}
                              aria-label={`Ver imagem ${index + 1}`}
                            >
                              <SideBySideMagnifier
                                imageSrc={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                                imageAlt={`Documento ${index + 1}`}
                                style={{ cursor: "pointer" }}
                              />
                            </button>
                          </div>
                        );
                      })
                  ) : (
                    <Typography variant="body2">
                      Nenhuma documentação anexada
                    </Typography>
                  )}
                </Carousel>

                <ReactViewer
                  visible={docsViewerVisible}
                  onClose={closeDocsViewer}
                  images={props.photos
                    .filter(
                      (photo) =>
                        vehicleDocsPhotosRefArray.includes(photo.ref) &&
                        !photo.foto.endsWith(".pdf")
                    )
                    .map((photo) => ({
                      src: `${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`,
                      alt: "Imagem da documentação",
                    }))}
                  index={docsCurrentImageIndex}
                  zIndex={1000}
                />
              </Card>
            </div>
            {openAnswerSurvey && (
              <Grid
                container
                xs={12}
                spacing={1}
                sx={{
                  marginBottom: "0.5rem",
                }}
              >
                <Grid item xs={6}>
                  <MuiFileInput
                    sx={{
                      width: "100%",
                    }}
                    value={values?.fotos?.vehiclePics?.vehiclePics || null}
                    onChange={(e) =>
                      handleInputChangeVehiclePic("vehiclePics", e)
                    }
                    placeholder={"Fotos do veículo"}
                    multiple
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiFileInput
                    sx={{
                      width: "100%",
                    }}
                    value={values?.fotos?.fileDocs?.docVehicle || null}
                    onChange={(e) => handleInputChange("docVehicle", e)}
                    placeholder={"Documentações"}
                    multiple
                  />
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Observações para o cliente"
                    variant="outlined"
                    className="w-100"
                    name="notes"
                    {...getFieldProps("bcNotesToCustomer")}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Observações para o vistoriador"
                    variant="outlined"
                    className="w-100"
                    name="notes"
                    {...getFieldProps("bcNotesToDealer")}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <LoadingButton
                    // enviar para rota
                    onClick={() => submitForm()}
                    variant="outlined"
                    style={{ width: "100%" }}
                    loading={isSubmitting}
                  >
                    Enviar
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <LoadingButton
                    onClick={() => setOpenAnswerSurvey(false)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    Cancelar
                  </LoadingButton>
                </Grid>
              </Grid>
            )}

            {!openAnswerSurvey && props?.status === 0 && (
              <Grid container xs={12} spacing={1}>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"space-evenly"}
                  xs={12}
                >
                  <LoadingButton
                    onClick={() => setAssignUser(true)}
                    style={{ width: `${assignedUser ? "50%" : "100%"}` }}
                    className={"mb-1 mt-3 m-2"}
                    variant="outlined"
                    loading={isLoading}
                  >
                    {assignedUser ? assignedUser.label : "Atribuir Vistoriador"}
                  </LoadingButton>
                  {assignedUser && (
                    <LoadingButton
                      onClick={() =>
                        toAssignSurvey({
                          props,
                          assignedUser,
                          bcNotesToDealer: values?.bcNotesToDealer,
                          bcNotesToCustomer: values?.bcNotesToCustomer,
                        })
                      }
                      style={{ width: "50%" }}
                      className={"mb-1 mt-3 m-2"}
                      variant="outlined"
                      loading={isAssigning}
                    >
                      Atribuir
                    </LoadingButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <LoadingButton
                    onClick={() => {
                      setOpenAnswerSurvey(true);
                      setDisableField(true);
                    }}
                    variant="outlined"
                    className={" m-2"}
                    style={{ width: "100%" }}
                  >
                    Realizar Vistoria
                  </LoadingButton>
                </Grid>
              </Grid>
            )}

            <Modal open={assignUser} onClose={() => setAssignUser(false)}>
              <Box className="d-flex justify-content-center align-items-center h-100">
                <Card className="w-75 align-self-center p-3">
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="h6">
                        Atribua a um vistoriador
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações para o cliente"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        {...getFieldProps("bcNotesToCustomer")}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Observações para o vistoriador"
                        variant="outlined"
                        className="w-100"
                        name="notes"
                        {...getFieldProps("bcNotesToDealer")}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <Autocomplete
                        // disablePortal
                        className="w-100 mt-2"
                        id="combo-box-demo"
                        options={usersWithOptions || []}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vistoriador responsável"
                          />
                        )}
                        onChange={(event, option) => {
                          setAssignedUser(option);
                          setAssignUser(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Modal>

            {props?.Replies?.length !== 0 && (
              <>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Mão de Obra"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(props?.Replies?.[0]?.labor || 0)}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Valor das peças"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(props?.Replies?.[0]?.pieces || 0)}
                    />
                  </Grid>

                  <Grid item xs={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Total"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={fCurrency(
                        Number(props?.Replies?.[0]?.pieces) +
                          Number(props?.Replies?.[0]?.labor) || 0
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Descrição"
                      disabled
                      variant="outlined"
                      rows={3}
                      multiline
                      className="w-100"
                      value={props?.Replies?.[0]?.additional}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <RenderPdfs pdfArray={props?.Replies} />
                  </Grid>

                  <Card
                    sx={{ boxShadow: 3, width: "100%" }}
                    className="mb-4 d-flex justify-content-center"
                  ></Card>
                  {props?.Replies?.length > 0 &&
                    props?.Replies?.[0]?.pdf?.endsWith(".pdf") && (
                      <Box
                        className="d-flex mb-1 mt-3"
                        style={{ maxWidth: "100vw", overflowX: "auto" }}
                      >
                        <Document
                          file={
                            props?.Replies?.[0]
                              ? `${process.env.REACT_APP_API_ENDPOINT}/replyes/${props?.Replies?.[0]?.pdf}`
                              : null
                          }
                          onLoadSuccess={onDocumentLoadSuccess}
                          renderMode="canvas"
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              renderTextLayer={false} // Desativa a renderização da camada de texto padrão
                              customStyles={customPageStyles} // Aplica estilos personalizados para cada página
                              renderAnnotationLayer={false}
                              scale={scale}
                            />
                          ))}
                        </Document>
                      </Box>
                    )}
                </Grid>
              </>
            )}
          </Scrollbar>
        </Card>

        <AlertSnackbar
          openAlert={showAlert}
          duration={2500}
          status={message.status}
          message={message.message}
          onClose={handleAlert}
        />
      </Container>
    </>
  );
}
