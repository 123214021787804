import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import imparLogo from "../img/newImparLogoP.png";

LoadingLogo.propTypes = {
  sx: PropTypes.object,
};

export default function LoadingLogo({ sx }) {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <img
        src={imparLogo}
        alt="Bateclick Logo"
        style={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
}
