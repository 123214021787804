import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
} from "@mui/material";

import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead } from "../sections/@dashboard/user";
import SurveySkeleton from "../components/skeleton/SurveySkeleton";
import SearchNotFound from "../components/SearchNotFound";
import NoListItems from "../components/NoListItems";
import { useUsers } from "../hooks/Services/useUsers";
import { fNewDateTime } from "../utils/formatTime";
import PagesToolbar from "../components/PagesToolbar";
import Iconify from "../components/iconify";
import { LoadingButton } from "@mui/lab";
import MoreMenu from "../components/MoreMenu";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Nome", alignRight: false },
  { id: "email", label: "E-mail", alignRight: false },
  { id: "createdAt", label: "Criado em", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function UsersListPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchingFor, setSearchingFor] = useState("");
  const { result, listUsers, isLoading, message } = useUsers();

  const navigate = useNavigate();

  useEffect(() => {
    handleListUsersParams();
  }, [page, rowsPerPage]);

  const handleReload = () => {
    setSearchingFor("");
    setRowsPerPage(20);
    setPage(0);
    listUsers(0, 20, "");
  };

  const handleListUsersParams = () => {
    if (searchingFor?.trim()?.length > 0) {
      setPage(0);
      listUsers(0, rowsPerPage, searchingFor);
      return;
    }
    listUsers(page, rowsPerPage, searchingFor);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (value) => {
    setSearchingFor(value);
  };

  const emptyRows = result?.total === 0 && searchingFor === "";
  const isProductNotFound = result?.total === 0 && searchingFor !== "";

  return (
    <>
      <Helmet>
        <title>Usuários</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              navigate("/users/create");
            }}
            sx={{
              color: "#ffffff !important",
              borderRadius: "4px !important",
              padding: "8px 16px !important",
              backgroundColor: "#0d6efd !important",
              transition: "0.2s",
              "&:hover": {
                backgroundColor: "#2065D1 !important",
              },
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Novo Usuário
          </LoadingButton>
        </Stack>

        <Card>
          <PagesToolbar
            searchingFor={searchingFor}
            onChange={handleFilterChange}
            onSearch={handleListUsersParams}
            reload={handleReload}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {isLoading && <SurveySkeleton numerOfRows={rowsPerPage} />}
                  {!isLoading &&
                    result?.data?.map((row, index) => (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell align="left">
                          {String(row?.name)?.toUpperCase() || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {String(row?.email) || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {String(fNewDateTime(row?.createdAt)) || "-"}
                        </TableCell>
                        <TableCell align="right" sx={{ padding: "16px 5px" }}>
                          <MoreMenu page="editUser" rowInfos={row} />
                        </TableCell>
                      </TableRow>
                    ))}
                  {!isLoading && emptyRows && (
                    <TableRow>
                      <TableCell align="center" colSpan={9}>
                        <Box sx={{ py: 3 }}>
                          <NoListItems pageTitle="usuários" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoading && isProductNotFound && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={searchingFor} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            count={result?.total ?? 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "15px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "15px",
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </Card>
      </Container>
    </>
  );
}
