import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

MoreMenu.propTypes = {
  page: PropTypes.string,
  rowInfos: PropTypes.object,
};

export default function MoreMenu({ page, rowInfos }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleChooseNavigate = () => {
    if (page === "editUser")
      return navigate("/users/edit", { state: { ...rowInfos } });
    if (page === "editCustomer")
      return navigate("/customers/edit", { state: { ...rowInfos } });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon="proicons:more-vertical" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: "fit-content", padding: "0.5rem" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={handleChooseNavigate}
        >
          <ListItemIcon>
            <Icon icon="iconamoon:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Editar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
