// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: "Início",
    path: "/dashboard/home",
    icon: icon("ic_home"),
  },
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: icon("ic_dashboard"),
  },
  {
    title: "Usuários",
    path: "/users/list",
    icon: icon("ic_userList"),
  },
  {
    title: "Clientes",
    path: "/customers/list",
    icon: icon("ic_customerList"),
  },
  {
    title: "Vistorias",
    path: "/dashboard/ticket",
    icon: icon("ic_ticket"),
  },
  {
    title: "Relatórios",
    path: "/dashboard/report",
    icon: icon("ic_report"),
  },
  // {
  //   title: 'Loja',
  //   path: '/dashboard/store',
  //   icon: icon('ic_shop'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
